/** @format */

import React, { useState } from "react";
import Box from "@mui/material/Box";
import MSButton from "../../submodule/components/MSButton/MSButton";
import DownloadButton from "../../submodule/components/DownloadButton/DownloadButton";
import AlertMessage from "../../submodule/components/AlertMessage/AlertMessage";
import { styled } from "@mui/system";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ListItem from "@mui/material/ListItem";
import {Link, useLocation, useParams} from "react-router-dom";
import { convertGMTtoDateTime } from "../../utils";
import BuySubscriptionModal from "../CatalogPage/BuySubscriptionModal/BuySubscriptionModal";
import {IProduct} from "../../shared/types";
import {Button} from "@mui/material";
import {usePostSubscriptionApi} from "../../api/SubscriptionApi";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  //...theme.typography.body2,
  padding: theme.spacing(1),
  boxShadow: "none",
  color: theme.palette.text.secondary,
}));

const SubscriptionDetailPage = () => {
  const [isUpdateSubscription, setUpdateSubscription] = useState(false);
  const params = useParams<{ id: string }>();
  const updateSubscription = usePostSubscriptionApi(params.id as string);
  const [messageAlert, setMessageAlert] = useState<{ type: string, message: string } | null>(null);

  const location = useLocation();
  const subscriptionDetail = location.state;

  const subscriptionDetailArr = [];
  subscriptionDetailArr.push(subscriptionDetail);

  // const id = location.state.id;
  // const [renewal, setRenewal] = useState(
  //   location.state != null ? location.state.will_auto_renew : false
  // );


  // const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {

  //   mutate({ id, renewal: (event.target as HTMLInputElement).value }, {
  //     onSuccess: () => {
  //       setMessage("success");
  //       location.state.will_auto_renew = (event.target as HTMLInputElement).value;
  //     },

  //     onError: () => {
  //       setMessage("fail");
  //     },
  //   });

  //   setAlert(true);
  //   setRenewal((event.target as HTMLInputElement).value);
  // };

  const columns = [
    "ID",
    "Offer ID",
    "Offer Name",
    "Offer Description",
    "Quantity",
    "Creation Date",
    "Effective Start Date",
    "Commitment End Date",
    "Cancellation Date",
    "Billing Cycle",
    "Billing Type",
    "Terms Duration",
    "Auto Renewal",
    "Is Trial",
    "Is NCE",
    "Status",
  ];

  // if (isLoading) {
  //   return <OverlayLoader />
  // }

  const onSaveHandler = (data: any) => {
    if (data.quantity > subscriptionDetail.quantity) {
      updateSubscription.mutate({ quantity: data.quantity }, {
        onSuccess() {
          onCloseModal();
          setMessageAlert({ type: "success", message: "Your subscription has been updated successfully" });
        },
        onError() {
          setMessageAlert({ type: "error", message: "Failed to update your subscription. Try again!" });
        }
      });
    }
  }

  const onUpdateSubscriptionClick = () => {
    setUpdateSubscription(true);
  }

  const onCloseModal = () => {
    setUpdateSubscription(false);
  }

  return (
    <div>
      <Box
        className="d-md-flex justify-content-md-between align-items-md-center"
        sx={{
          marginBottom: `10px`,
        }}
      >
        <h1>
          {/* <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}> */}
          {location?.state?.is_nce ?
            <>
              <Box component="div" sx={{ display: 'inline', backgroundColor: "#0a7eca", borderRadius: '5px', fontSize: '12px', color: '#fff' }} px={1} py={1} mr={2} >NCE</Box>
            </>
            : null}
          {location.state.offer_name}{" "}

          <span className={`ms-${location.state.status === "active" ? "success" : (location.state.status === "suspended" ? "danger" : "primary")
            }`}>
            {location.state.status}
          </span>
          {/* </Box> */}
        </h1>
        {/* */}
        <DownloadButton
          rows={subscriptionDetailArr}
          columns={columns}
          filename="subscriptionDetail.csv"
        />
      </Box>
      <Box sx={{ flexGrow: 1 }}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6} lg={5}>
            <Item className="d-md-flex align-items-md-center">
              <h3 className="ms-meta-title">Quantity: </h3>
              <h3 className="ms-meta-data">{location.state.quantity}</h3>
            </Item>
          </Grid>
          <Grid item xs={12} md={6} lg={7}>
            <Item className="d-md-flex align-items-md-center">
              <h3 className="ms-meta-title">Subscription Period: </h3>
              <h3 className="ms-meta-data">{location.state.billing_cycle.charAt(0).toUpperCase() + location.state.billing_cycle.slice(1)}</h3>
            </Item>
          </Grid>


          {/* <Grid item xs={12} md={6} lg={5}>
            <Item className="d-md-flex align-items-md-center">
              <h3 className="ms-meta-title">Auto-Renewal: </h3>
              <RadioGroup
                row
                value={renewal}
                onChange={handleChange}
                aria-labelledby="demo-row-radio-buttons-group-label"
                name="row-radio-buttons-group"
              >
                <FormControlLabel
                  value={true}
                  control={<Radio />}
                  label="Enable"
                />
                <FormControlLabel
                  value={false}
                  control={<Radio />}
                  label="Disable"
                />
              </RadioGroup>
            </Item>
          </Grid> */}
          <Grid item xs={12} md={6} lg={7}>
            <Item className="d-md-flex align-items-md-center">
              <h3 className="ms-meta-title">Subscription Type: </h3>
              <h3 className="ms-meta-data">{location.state.billing_type.charAt(0).toUpperCase() + location.state.billing_type.slice(1)} </h3>
            </Item>
          </Grid>
          <Grid item xs={12} md={6} lg={7}>
            <Item className="d-md-flex align-items-md-center">
              <h3 className="ms-meta-title">Renews On: </h3>
              <h3 className="ms-meta-data">{convertGMTtoDateTime(location.state.commitment_end_date)} </h3>
            </Item>
          </Grid>
          <Grid item xs={12} md={6} lg={7}>
            <Item className="d-md-flex align-items-md-center">
              <h3 className="ms-meta-title">Term Duration: </h3>
              <h3 className="ms-meta-data">{location.state.term_duration} </h3>
            </Item>
          </Grid>
        </Grid>
      </Box>
      <Box sx={{ marginTop: `30px` }}>
        {
          location.state.offer_description !== "" && (
            <div className="panel-light">
              <h2>Description</h2>
              <p className="ms-description-summary">
                {location.state.offer_description}
              </p>
            </div>
          )
        }


        <br></br>
        <div className="d-flex justify-content-between align-items-center">

          <ListItem
            component={Link}
            to={"/app/subscription"}
            state={{ activeSideBar: location.state?.activeSideBar }}
          >
            <MSButton
              text="Back"
              backgroundColor="#9BA4AF"
              icon={<ArrowBackIosIcon />}
            />
          </ListItem>

          <Button
            size="small"
            style={{
              paddingLeft: `30px`,
              paddingRight: `30px`,
              height: `50px`,
              borderRadius: `50px`,
              textTransform: `inherit`,
              font: `normal normal 500 20px/80px Raleway`,
              marginRight: "6px"
            }}
            variant="contained"
            onClick={onUpdateSubscriptionClick}
            className="buy-sub"
            disabled={subscriptionDetail?.status === "suspended"}
          >
            Update
          </Button>
        </div>
      </Box>
      {isUpdateSubscription && (
        <BuySubscriptionModal
          product={{ title: subscriptionDetail.offer_name } as IProduct}
          onClose={onCloseModal}
          onSave={onSaveHandler}
          isSaving={updateSubscription.isLoading}
          isUpdatingSubscription
          defaultFormState={{ quantity: subscriptionDetail.quantity, termDuration: subscriptionDetail.term_duration, billingCycle: subscriptionDetail.billing_cycle, agreeTermAndCondition: false }}
        />
      )}
      {messageAlert && (AlertMessage(true, messageAlert.message, messageAlert.type, () => setMessageAlert(null)))}
    </div>
  );
};

export default SubscriptionDetailPage;
