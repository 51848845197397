import debounce from "lodash.debounce";
import {IPurchaseOptionsAsObject} from "../shared/types";

export function convertGMTtoDateTime(gmtDate: string | number) {
  const dateTime = new Date(gmtDate);
  const dateTimeString = dateTime.toLocaleString();
  return dateTimeString;
}

export function convertGMTtoDate(gmtDate: string | number) {
  const createdDate = new Date(gmtDate);
  const dateString = createdDate.toLocaleDateString();
  return dateString;
}

export const debounceDelay = (fn: any, delay: number) => {
  return debounce(fn, delay);
}

export const getPriceInFormat = (number: number) => {
  return Number(number).toFixed(2)
}

export const getPurchaseOptionsAsObject = (purchaseOptions: any[]): IPurchaseOptionsAsObject => {
  return purchaseOptions ? purchaseOptions.reduce((accum: any, item: any) => {
    return ({ ...accum, [item.term_duration]: { ...accum[item.term_duration], [item.billing_cycle]: item } })
  }, {}): [];
}