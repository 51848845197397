/** @format */

import { Button, Grid, Typography } from "@mui/material";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { useNavigate } from "react-router-dom";
import "./policies.scss";
import { AppImages } from "../../images";
import "./Archive.scss";

const { logo } = AppImages;

const PrivacyPolicyPage = () => {
  const navigate = useNavigate();
  const handleBack = () => {
    navigate(-1);
  };
  return (
    <Grid className="archive">
      <Grid item className="archive-top-sec">
        <img className="app-logo" src={logo} alt="logo" />
      </Grid>
      <Grid item className="archive-head">
        <Button className="back-btn" onClick={handleBack}>
          <ArrowBackIosIcon className="back-arrow" fontSize="small" /> Back
        </Button>
        <Typography className="page-title">Privacy Policy</Typography>
        <a href='/PrivacyPolicy.pdf' download='Privacy Policy' target="_blank">

        <Button className="download-btn">Download</Button>
        </a>
      </Grid>
      <Grid item className="archive-content">
        <Typography className="archive-paras">

      <h3>Effective Date: 5/4/2017 Last Updated: August 2018</h3>
<p>Managed Solution LLC, including its wholly or partly owned (directly and indirectly) subsidiaries (hereinafter &quot;Managed Solution&quot;) is committed to protecting the privacy and security of the personal information of our customers, employees and third parties.  Managed Solution is committed to maintaining and safeguarding the privacy of the information you share with us through our website (portal.managedsolution.com) as well as our service provider business. This privacy notice discloses the privacy practices for (portal.managedsolution.com and the company, Managed Solution).</p>
<p>This Privacy Notice highlights how Managed Solution uses the information collected through its websites, apps, services and technology solutions.</p>
<h5>1. Information We Collect<span>1.1 Summary</span></h5>
<p>Managed Solution collects many kinds of information in order to operate effectively and provide you the best products, services and experiences we can. We collect information when you register, sign in and use our sites and services or call in / meet for our support or projects. We also may get information from other companies or your employer if we are engaged in business. We collect this information in a variety of ways, including from issue logs, service reports, web forms, technologies like cookies, web logging and software on your computer or other device.</p>
<p>We are the sole owners of the information collected on this site or in our support databases. We only have access to/collect information that you voluntarily give us via form submit, email or other direct contact from you or your employer. We will not sell or rent this information to anyone.</p>
<p>We will use your information to respond to you, regarding the reason you contacted us. We will not share your information with any third party outside of our organization, other than as necessary to fulfill your request, e.g., to ship an order.</p>
<p>Unless you ask us not to, we may contact you via email in the future to tell you about technology tips, trends, new products or services, or changes to this privacy policy.</p>
<p>Additionally, we use information submitted through our websites or collected through email or hard copy surveys to analyze the characteristics of our customers and improve our services. Occasionally, we will provide anonymized aggregated statistics about our customers in reports made available to our customers, to third parties, or to the public. These reports will not identify individual Managed Solution customers and will include no personally identifiable data, not personal data.</p>
<p>If you request information about Managed Solution products and services, we will collect your name, email address, phone number, and additional company information. We use this information to contact you about your request, and to follow up with you about the products and services in which you have expressed an interest. We will also send information to you regarding upcoming Managed Solution events, webinars, and relevant privacy related news and alerts. You may unsubscribe from these communications at any time by clicking on the unsubscribe link provided in the email. Additionally, a natural person may request a deletion of marketing information collected and this request will be processed and personal data expunged.</p>
<p>Managed Solution receives and processes information (in paper and electronic form) in accordance with its clients&rsquo; instructions for the purpose of providing services to its customers. At Managed Solution, we recognize the importance of privacy to our customers and we strive to safeguard any personal information we receive and may need to use in support of our customers.</p>
<p>We respect each individual&rsquo;s right to personal privacy. We will collect and use information we receive directly from you only in the ways disclosed in this Privacy Policy. This policy does not apply to practices that Managed Solution does not own or control, or to individuals that Managed Solution does not employ or manage. Managed Solution adheres to the set of data protection principles developed by the United States Department of Commerce (DOC), the State of California, and follows the standards required by the European Union&rsquo;s General Data Protection Regulation.</p>
<h5><span>1.2 Examples</span></h5>
<p>Managed Solution collects many kinds of information in order to operate effectively and provide you the best products, services and experiences we can. Some of this information you provide directly to us. Some of it we get by observing how you interact with our products and services. Some of it is available from other sources that we may combine with the data we collect directly. Regardless of the source, we believe it&rsquo;s important to treat that information with care and to help you maintain your privacy.</p>
<p>Examples of what we collect:</p>
<p>&ndash; Registration</p>
<p>When you sign up to use our sites or services you may be required to provide information about yourself, such as your name, birthdate, organization name, e-mail and postal address and postal code.</p>
<p>&ndash; Signing in</p>
<p>To access some Managed Solution services, you will need to sign in with a user name and password, which we refer to as your user account.</p>
<p>&ndash; Using our sites and services</p>
<p>We collect information that tells us how you interact with our services, including the browser you&rsquo;re using, your IP address, location, cookies or other unique IDs, the pages you visit and features you use (of our services).</p>
<p>&ndash; Data from other sources &ndash; We may get additional information about you, such as demographic data we purchase from other companies.</p>
<p>How we collect:</p>
<p>We use a number of methods and technologies to gather information about how you use our sites and services, such as:</p>
<p className="bullet">&bull; Web forms, such as when you type information into a registration form or type a search query into a search box.</p>
<p className="bullet">&bull; Technologies like cookies and web beacons (Please visit Section 2 of this Privacy Policy page to learn more about these technologies).</p>
<p className="bullet">&bull; Web logging, which enables us to collect the standard information your browser sends to every web site you visit &ndash; such as your IP address, browser type and language, and the site you came from &ndash; as well as the pages you visit and the links you click while using our sites and services.</p>
<p className="bullet">&bull; Software installed on your computer or other device, which may send back information needed to operate, update or improve that software.</p>
<p>Customer Data:</p>
<p>Managed Solution receives and processes information (in paper and electronic form) in accordance with its clients&rsquo; instructions for the purpose of providing services to its customers. At Managed Solution, we recognize the importance of privacy to our customers and we strive to safeguard any personal information we receive and may need to use in support of our customers.</p>
<p>Managed Solution Customer Information</p>
<p>Customer Information is information we collect from you when you purchase and contract with us to provide services or technology solutions. We will ask you to provide the following information:</p>
<p className="bullet">&bull; Email address;</p>
<p className="bullet">&bull; Phone number;</p>
<p className="bullet">&bull; Information regarding your role within your company;</p>
<p className="bullet">&bull; Company information, including company name, address, billing information (e.g., P.O. number, bank wire information, credit card number), and company size; and</p>
<p className="bullet">&bull; Contact information for other relevant points of contact within your company as needed for Managed Solution to provide its services.</p>
<p className="bullet">&bull; Information regarding the issue or topic being reached out over;</p>
<p>If you purchased one of Managed Solution&rsquo;s technical solutions you may have the option to add additional users to your account. We will ask for the name and email address of the additional users you want to add. This information is used by Managed Solution to provide the requested service.</p>
<p>In the course of using Managed Solution&rsquo;s technical solutions or services, you may provide business information related to your company or employees. Business information may include copies of your company&rsquo;s policies and process documents, responses to assessment questionnaires and evidence to support those responses. This is information is stored on Managed Solution&rsquo;s systems, and is used to provide contracted services in accordance with the applicable terms and conditions of agreements between Managed Solution and your company.</p>
<h5>2. Children<span>2.1 Restrictions of Access</span></h5>

<p>When on a Managed Solution site or it&rsquo;s service collects age information, it is against our policy to collect or approve any data from users under 16.</p>
<h5>3. Cookies &amp; Similar Technologies<span>3.1 Summary</span></h5>
<p>Most Managed Solution web sites and some tools use &quot;cookies,&quot; small pieces of data that can be read by a web server in the same domain that put the cookie on your hard drive. We may use cookies to help us retrieve your preferences and settings; help with sign-in; to combat fraud; and analyze site operations.</p>
<p>We also use web beacons to help deliver cookies and compile analytics. These may include third-party web beacons, which are prohibited from collecting your personal information.</p>
<p>You have a variety of tools to control cookies and similar technologies, including:</p>
<p>&ndash; Browser controls to block and delete cookies; and</p>
<p>&ndash; Controls from some analytics providers to opt out of data collection in web beacons.</p>
<h5><span>2.2 Full text</span></h5>
<h5><span>2.2.1 Our Use of Cookies</span></h5>
<p>Most Managed Solution web sites use &quot;cookies,&quot; which are small pieces of data sent to your browser by a web server. Upon subsequent requests, your browser will send the cookie data to the same web server, or other web servers in the same domain, when accessing the web site. Cookies contain data that can be read by a web server in the domain that issued the cookie to you. That data often consists of a string of numbers and letters that uniquely identifies your computer, but may contain other information as well. Here is an example of a unique ID number stored in a cookie that Managed Solution might place on your device when you visit one of our web sites: 0aj17be34l7srju0qcdnftigt0</p>
<p>We may use cookies for:</p>
<p>&ndash; Sign-in and Authentication</p>
<p>When you sign in to a site or service using your account on an Managed Solution web site, we store a unique ID number, and the time you signed in, in an encrypted cookie on your hard disk. This cookie allows you to move from page to page at the site without having to sign in again on each page. When you sign out, these cookies are deleted from your computer. This is commonly referred to as a &quot;session cookie&quot;. We also use cookies to improve the sign-in experience. For example, your user name may be stored in a cookie that will remain on your computer after you sign out. This cookie allows your user name to be pre-populated, so that you will only need to type your password the next time you sign in. If you are using a public computer or do not want this information to be stored, you can select the appropriate radio button on the sign-in page, and this cookie will not be used.</p>
<p>&ndash; Site Analytics</p>
<p>We may use cookies to count the number of unique visitors to a web page or service or to develop other aggregate statistics about the operations of our sites and services. These analytics help us operate and improve the performance of these sites and services.</p>
<p>In addition to the cookies Managed Solution may set when you visit our web sites, third parties may also set certain cookies on your device when you visit Managed Solution sites. In some cases, that is because we have hired the third party to provide certain services on our behalf, such as site analytics. In other cases, it is because our web pages contain content or ads from third parties, such as videos, news content or ads delivered by other ad networks. Because your browser connects to those third parties&rsquo; web servers to retrieve that content, those third parties can set or read their own cookies on your device and may collect information about your online activities across websites or online services.</p>
<h5><span>2.2.2 How to Control Cookies</span></h5>
<p>&ndash; Browser Controls to Block Cookies.</p>
<p>Most web browsers automatically accept cookies, but you can usually modify your browser setting to block cookies. Instructions for blocking cookies in different browsers are usually available at each browser&rsquo;s privacy statement or in their help files or settings pages.</p>
<p>Please be aware that if you choose to block cookies, you may not be able to sign in or use other interactive features of Managed Solution sites and services that depend on cookies. This will not stop you from being able to call in for support, however it can restrict the use of some tools for the support.</p>
<p>&ndash; Browser Controls to Delete Cookies</p>
<p>If you accept cookies, you can delete them later. Instructions for deleting cookies in different browsers are usually available at each browser&rsquo;s privacy statement or in their help files or settings pages.</p>
<p>Please be aware that if you choose to delete cookies, any settings and preferences controlled by those cookies will be deleted and may need to be recreated.</p>
<p>&ndash; Browser Controls for &quot;Do Not Track&quot; and Tracking Protection</p>
<p>Some newer browsers have incorporated &quot;Do Not Track&quot; features. Most of these features, when turned on, send a signal or preference to the web sites you visit indicating that you do not wish to be tracked. Those sites (or the third-party content on those sites) may continue to engage in activities you might view as tracking even though you have expressed this preference, depending on the sites&rsquo; privacy practices. Because there is not yet a common understanding of how to interpret the DNT signal, Managed Solution does not currently respond to the browser DNT signals on its own websites or online services, or on third-party websites or online services where Managed Solution provides content or is otherwise able to collect information.</p>
<p>If you feel that we are not abiding by this privacy policy, you should contact us immediately via telephone at 800-550-3795.</p>


        </Typography>
      </Grid>
    </Grid>
  );
};

export default PrivacyPolicyPage;
