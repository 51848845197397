/** @format */
import axios from "axios";
import {  useMutation, useQuery, useQueryClient } from 'react-query';
import { getAuthorizationHeader } from "../constants";


export function useSubscriptionGetApi() {
  return useQuery('subscriptions', () => axios.get(`${process.env.REACT_APP_CLIENT_API_BASE}/subscriptions`, {headers: getAuthorizationHeader()})
    .then((response) => {
    return response.data
  }),
  {
    retry:2
  }
  )
}

export const usePostSubscriptionApi = (subscriptionId: string) => {
  const queryClient = useQueryClient()
  return useMutation((data: any) => {
    return axios.post(`${process.env.REACT_APP_CLIENT_API_BASE}/subscription/${subscriptionId}`, {
      quantity: String(data.quantity)
    }, {
      headers: getAuthorizationHeader()
    })
  }, {
    onSuccess() {
      queryClient.invalidateQueries('subscriptions');
    }
  });
}
