/** @format */

import React from "react";
import { Button, Grid, CircularProgress, Box } from "@mui/material";
import LockIcon from "@mui/icons-material/Lock";
import { useSearchParams } from "react-router-dom";

import Footer from '../admin/Footer/Footer';
import './SignIn.scss';
import { CustomizedDialogs, LogoutPopup, ErrorPopup, TimeoutPopup } from '../AuthPopup/AuthPopup'
import { SubModAppImages } from "../../constants/constants";
const { microsoft, logo, loginPopupImage } = SubModAppImages;

const SignIn = (props: any) => {
  const [searchParams] = useSearchParams();
  return (
    <Grid
      container
      direction="row"
      justifyContent="center"
      alignItems="center"
      className="vh-100 app-login-bg"
    >
      <Grid item>
        <div className="app-login">
          <img className="app-logo" src={logo} alt="logo" />
          <div className="login-inner">
            <div className="inner-content">
              <div className="content-left">
                <h3 className="sign-in">
                  <LockIcon className="lock" /> Sign In
                </h3>
                <h5>to the {props.userType} Portal</h5>
              </div>
              <div className="content-right">
                {searchParams.get("e") === "signout" && <LogoutPopup />}
                {searchParams.get("e") === "timeout" && <TimeoutPopup errorMessage={searchParams.get("e")} />}
                {searchParams.get("e") !== "signout" && searchParams.get("e") !== "timeout" &&
                  searchParams.get("e") !== null && (
                    <ErrorPopup errorMessage={searchParams.get("e")} />
                  )}
                <CustomizedDialogs popup={loginPopupImage} />
              </div>
            </div>
            {props.error ? <CustomizedDialogs popup={loginPopupImage} isOpen={true} /> :
              <>
                {props?.loading ?
                  <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: '60px' }}>


                    <CircularProgress />
                  </Box> :
                  <Button className="btn-submit" onClick={props.attemptLogin}>
                    <img className="btn-img" src={microsoft} alt="microsoft-logo" />
                    <h3 className="btn-text">Continue with Microsoft</h3>
                  </Button>
                }
              </>
            }
          </div>
        </div>
      </Grid>
      <Footer />
    </Grid>
  )
}

export default SignIn
