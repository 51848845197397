/** @format */
import axios from "axios";
import {  useQuery } from 'react-query';
import { getAuthorizationHeader } from "../constants";


export function useOfferGetApi() {
  return useQuery('offers', () => axios.get(`${process.env.REACT_APP_CLIENT_API_BASE}/offers`, { headers: getAuthorizationHeader() })
    .then((response) => {
      return response.data
    })
    ,{
      retry:2
    }
    
  );
  
}

