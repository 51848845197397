/** @format */
//@ts-nocheck

import React, { useState, useEffect, useMemo } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Box from "@mui/material/Box";
import DownloadButton from "../../submodule/components/DownloadButton/DownloadButton";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import SearchBar from "../../submodule/components/SearchBar/SearchBar";
import "./Orders.scss";
import {
  stableSort,
  getComparator,
  Order,
} from "../../submodule/components/Tables/Table";
import { TableStyled } from "../../submodule/components/Tables/TableStyles";
import EnhancedTableHead from "../../submodule/components/Tables/TableHead";
import { useOrderGetApi } from "../../api/OrderApi";
import { convertGMTtoDate } from "../../utils";
import Loader from "../../components/Loader";
import { TablePagination, Tooltip } from "@mui/material";



interface Data {
  id: any;
  creation_date: string;
  billing_cycle: any;
  offer_name: any;
  term_duration: any;
  status: any;
  line_items: {
    offer_name: string;
    term_duration: string;
  }[];
  // action: any;
}


interface HeadCell {
  disablePadding: boolean;
  id: keyof Data;
  label: string;
  numeric: boolean;
}

const columns = [
  "ID",
  "Customer ID",
  "Data",
  "Line Items",
  "Currency Code",
  "Billing Cycle",
  "Monthly Price",
  "Total Price",
  "Status",
];
// HeadCell;

const headCells: readonly HeadCell[] = [
  {
    id: "offer_name",
    numeric: false,
    disablePadding: true,
    label: "Offer name",
  },
  {
    id: "creation_date",
    numeric: false,
    disablePadding: true,
    label: "Date",
  },
  {
    id: "billing_cycle",
    numeric: false,
    disablePadding: true,
    label: "Billing Cycle",
  },
  {
    id: "term_duration",
    numeric: true,
    disablePadding: true,
    label: "Term Duration",
  },
  {
    id: "status",
    numeric: false,
    disablePadding: true,
    label: "Status",
  },
];

const OrdersPage = () => {
  const { data, isFetching: orderFetching } = useOrderGetApi();
  const originalRows: Data[] = useMemo(() => {
    return data
  }, [data])
  useEffect(() => {
    if (data) {
      setRows(originalRows)
    }
  }, [data, originalRows])
  const [rows, setRows] = useState<Data[]>(originalRows || []);



  const [searched, setSearched] = useState<string>("");
  const [order, setOrder] = React.useState<Order>("desc");
  const [orderBy, setOrderBy] = React.useState<keyof Data>("creation_date");
  const [page,setPage] = useState(0);
  const [rowsPerPage,setRowsPerPage] = useState(15);
  const location = useLocation();
  const navigate = useNavigate();

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number,
  ) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };


  const requestSearch = (searchedVal: string) => {
    const filteredRows = data.filter((row: { line_items: { offer_name: string; }[]; }) => {
      return row.line_items[0]?.offer_name.toLowerCase().includes(searchedVal.toLowerCase().trim());
    });
    setRows(filteredRows);
  };

  const onChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setSearched(event.target.value as string);
    requestSearch(event.target.value as string);
  };


  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: keyof Data
  ) => {
    const isAsc = orderBy === property && order === "desc";
    setOrder(isAsc ? "asc" : "desc");
    setOrderBy(property);
  };


  const clickableRow = (row: any) => {
    navigate(`detail/${row.id}`, {
      state: { ...row, ...{ activeSideBar: location.state?.activeSideBar } },
    });
  };




  return (
    <div>
      <Box sx={{ textAlign: `right`, marginBottom: `30px`, display:'flex', alignItems:'center' }}>
      <SearchBar value={searched} onChange={onChange} />
        <DownloadButton rows={rows} columns={columns} filename="order.csv" />
      </Box>
      <Box>
    
        {orderFetching ?
          <Box sx={{ display: "flex", justifyContent: 'center', marginTop: "30px" }}>
            <Loader />
          </Box>
          :
          <>
          <Paper sx={{ boxShadow: "none" }}>
            <TableContainer
              className="order-table-container"
              style={{ marginTop: "30px" }}
            >
              <TableStyled
                stickyHeader
                //className={classes.table}
                aria-label="sticky table"
              >
                <EnhancedTableHead
                  order={order}
                  orderBy={orderBy}
                  onRequestSort={handleRequestSort}
                  rowCount={rows?.length}
                  headCells={headCells}
                />
                <TableBody>
                  {
                    rows.length <= 0 ?
                      <TableCell colSpan={6} sx={{ textAlign: `center` }}>
                        No data found.
                      </TableCell>
                      :
                      stableSort(rows, getComparator(order, orderBy))?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)?.map((row) => {
                        return (
                          <TableRow
                            hover
                            tabIndex={-1}
                            key={row?.id}
                            onClick={() => clickableRow(row)}
                          >
                            <TableCell width='30%'>
                              <Tooltip title={row.line_items[0]?.offer_name} placement="top" arrow>
                                <span>
                                  {row?.line_items[0]?.offer_name}
                                </span>
                              </Tooltip>
                            </TableCell>
                            <TableCell>{convertGMTtoDate(row?.creation_date)}</TableCell>
                            <TableCell>{row?.billing_cycle}</TableCell>
                            <TableCell>{row?.line_items[0]?.term_duration}</TableCell>
                            <TableCell>
                              <span className={`ms-${row?.status === "completed" ? "success" : (row?.status === "cancelled" ? "danger" : "primary")
                                }`}>
                                {row?.status}
                              </span>
                            </TableCell>
                          </TableRow>
                        );
                      })
                  }
                </TableBody>
              </TableStyled>
            </TableContainer>
          </Paper>
           <TablePagination
           sx={{background:'#e3e3e3', borderBottomLeftRadius:'10px', borderBottomRightRadius:'10px'}}
             rowsPerPageOptions={[15, 25, 50]}
             component="div"
             count={rows?.length}
             rowsPerPage={rowsPerPage}
             page={page}
             onPageChange={handleChangePage}
             onRowsPerPageChange={handleChangeRowsPerPage}
          />
          </>
        }
       
      </Box>
    </div>
  );
};

export default OrdersPage;
