/** @format */

import { useHelloApi } from "../../api/HelloApi";
import SignIn from "../../submodule/components/SignIn/SignIn";

const Login = (props: any) => {

  const { isFetching, isError } = useHelloApi()

  const attemptLogin = () => {
    window.location.href = `${process.env.REACT_APP_API_BASE}/sso/v1/signin/portal`;
  };

  let userType = props.userType;
  userType = userType.toLowerCase().replace(/\b[a-z]/g, function (letter: any) {
    return letter.toUpperCase();
  });

  return <SignIn userType={userType} attemptLogin={attemptLogin} error={isError} loading={isFetching} />;
};

export default Login;
