import React from 'react';
import { createRoot } from 'react-dom/client';
import Routes from "./routes";
import { ThemeProvider } from '@mui/material/styles';
import './submodule/styles/index.scss';
import { MuiThemeCustomized } from "./submodule/styles/mui/themeStyles";
import { QueryClient, QueryClientProvider } from 'react-query'


const container = document.getElementById('root')!;
const root = createRoot(container);
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      keepPreviousData: true,
      staleTime: Infinity,
      retry: 2,
    }
  }
});



root.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient} >
      <ThemeProvider theme={MuiThemeCustomized}>
        <Routes />
      </ThemeProvider>
    </QueryClientProvider>
  </React.StrictMode>
);