/** @format */

import {
  Button,
  Container,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  TextField,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import AlertMessage from "../AlertMessage/AlertMessage";
import { useMessageApi } from "../../api/messagesApi";
import ReactPhoneInput from 'react-phone-input-material-ui';
import "react-phone-input-material-ui/lib/style.css";


const defaultValue = {
  email: "",
  phone: "",
  subject: "",
  message: "",
  contact: "Email",
};

// Todo: refactor
const ContactUsForm = ({ page }: { page: string }) => {
  const [formValues, setFormValues] = useState(defaultValue);
  const { mutate, isLoading } = useMessageApi();
  const [message, setMessage] = useState<string>("");
  const [alert, setAlert] = useState<boolean>(false);
  const [phone, setPhone] = useState('')


  const handleInputChange = (e: any) => {
    const { name, value }: any = e.target;
    setFormValues({
      ...formValues,
      [name]: value,
    });
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();

    const requestBody = {
      source: "portal",
      page: page,
      data: {
        Email: `${formValues.email}`,
        "Phone Number": `${phone}`,
        Subject: `${formValues.subject}`,
        Message: `${formValues.message}`,
        "How would you like us to contact you?": `${formValues.contact}`,
      },
    };



    mutate(requestBody, {
      onError: () => {
        setMessage("fail");
        setAlert(true);
      },
      onSuccess: () => {
        setMessage("success");
        setAlert(true);
        setFormValues(defaultValue);
      }
    })

  };

  useEffect(() => {
    // when the component is mounted, the alert is displayed for 5 seconds
    setTimeout(() => {
      setAlert(false);
    }, 5000);
  }, []);

  return (
    <Container>
      {message === "success"
        ? AlertMessage(alert, "Message Sent Succefully", "success")
        : message === "fail" &&
        AlertMessage(alert, "Message Sent Failed", "error")}
      <h2 className="primary-heading">Send Us a Message</h2>
      
      <form onSubmit={handleSubmit}>
        <FormControl className="input-row">
          <TextField
            fullWidth
            required
            type="email"
            name="email"
            label="Your email address"
            variant="outlined"
            value={formValues.email}
            onChange={handleInputChange}
          />
        </FormControl>
        <FormControl className="input-row">
     

<ReactPhoneInput
        inputProps={{required:true, name:"phone"}}
        value={phone}
        onChange={phone => setPhone(phone)} // passed function receives the phone value
        component={TextField}
        disableDropdown = {false}
        inputStyle={{height:"56px"}}
        enableSearch
        dropdownStyle={{overflowX:'hidden', width:'400px'}}
        disableSearchIcon
        
      />
      
        </FormControl>
        <FormControl className="input-row">
          <TextField
            fullWidth
            required
            type="text"
            label="Subject"
            variant="outlined"
            name="subject"
            value={formValues.subject}
            onChange={handleInputChange}
          />
        </FormControl>
        <FormControl className="input-row">
          <TextField
            fullWidth
            required
            label="Message"
            multiline
            rows={5}
            onChange={handleInputChange}
            name="message"
            value={formValues.message}
          />
        </FormControl>

        <div className="home-contact-option d-md-flex justify-content-between mt-30">
          <FormControl className="contact-radio-button">
            <FormLabel>How would you like us to contact you?</FormLabel>
            <RadioGroup
              row
              value={formValues.contact}
              name="contact"
              onChange={handleInputChange}
            >
              <FormControlLabel
                value="Email"
                control={<Radio />}
                label="Email"
              />
              <FormControlLabel
                value="Phone"
                control={<Radio />}
                label="Phone"
              />
            </RadioGroup>
          </FormControl>
          <Button
            type="submit"
            className="btn-rounded btn-mid"
            variant="contained"
            disabled={isLoading}
          >
            Send Message
          </Button>
        </div>
      </form>
    </Container>
  );
};

export default ContactUsForm;
