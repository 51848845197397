/** @format */

import { getImageProxy } from "./submodule/utils/utils";

const basePath = `${process.env.PUBLIC_URL}/images`;

const AppImagesPaths = {
  logo: "logo.png",
  sortingIcon: "sortingIcon.svg",
  logoBg: "logoBg.png",
  img404: "img-404.png",
  supportImg: "support-img.png",
  email: "email.png",
  hour24: "24hour.png",
  setting: "setting.png",
  microsoft: "microsoft.png",
  loginPopupImage: "login-pop-up-img.png",
  slidebg1: "slide-bg-2.png",
  excel: "excel.png",
  cloud: "cloud.png",
  e: "e.png",
  azure: "azure.png",
  office: "office.png",
  v: "v.png",
  word: "word.png",
  microsoftRound: "round-microsoft.png",
  roundAward: "round-award.png",
  roundAzure: "round-azure.png",
  windows: "windows.png",
  about: "about.png",
  cta1: "cta1.png",
  cta2: "cta2.png",
};

export const AppImages = getImageProxy(basePath, AppImagesPaths);
