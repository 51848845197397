/** @format */
import axios from "axios";
import { useQuery } from "react-query";


export const useHelloApi = () => {
   return  useQuery("login", () =>
    axios.get(`${process.env.REACT_APP_API_BASE}/sso/v1/hello`),{retry:2}
  );
};
