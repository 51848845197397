/** @format */

import React from "react";
import { Button, Grid, Typography } from "@mui/material";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { useNavigate  } from "react-router-dom";

import "../PrivacyPolicyPage/policies.scss";
import { AppImages } from "../../images";
import "../PrivacyPolicyPage/Archive.scss";

const { logo } = AppImages;

const TermsConditionsPage = () => {
  const navigate = useNavigate();
  const handleBack = () => {
    navigate(-1);
  };
  return (
    <Grid className="archive">
      <Grid item className="archive-top-sec">
        <img className="app-logo" src={logo} alt="logo" />
      </Grid>
      <Grid item className="archive-head">
        <Button className="back-btn" onClick={handleBack}>
          <ArrowBackIosIcon className="back-arrow" fontSize="small" /> Back
        </Button>
        <Typography className="page-title">Terms & Conditions</Typography>
        <a href='/TermsandConditions.pdf' download='Terms and Condition' target="_blank">

        <Button className="download-btn">Download</Button>
        </a>
      </Grid>
      <Grid item className="archive-content">
        <Typography className="archive-paras">
        <h5>Agreement Length for Annual Term Subscriptions</h5>
<p>This Agreement is based on the term of each <u>individual</u> subscription chosen above and cannot be terminated within the annual term. Customer agrees to purchase an annual term and commits to a minimum one-year term. Subscriptions will be automatically renewed for successive yearly periods at the end of each <u>individual</u> subscription term, unless a Change Request is made <strong><u>at least 30 days prior</u></strong> to the end of an annual term subscription. See Change Request and Subscription Levels below for additional information. Upon auto-renewal, Managed Solution will bill Customer the then-current rate for each <u>individual</u> subscription deemed by Microsoft. Managed Solution will provide notice to Customer of any rate changes.</p>
<h5>Agreement Length for Monthly Term Subscriptions</h5>
<p>This Agreement is based on the term of each <u>individual</u> subscription chosen above cannot be terminated within the monthly term. Customer agrees to purchase a monthly term and commits to a minimum one-month term. Subscriptions will be automatically renewed for successive monthly periods at the end of each <u>individual</u> subscription term, unless a Change Request is made to Managed Solution <strong><u>at least 72 hours prior</u></strong> to the end of Monthly term. See Change Request and Subscription Levels below for additional information. Upon auto-renewal, Managed Solution will bill Customer the then-current rate for each <u>individual</u> subscription deemed by Microsoft. Managed Solution will provide notice to Customer of any rate changes.</p>
<h5>Microsoft Premier Support</h5>
<p>Microsoft Premier Support is available at no additional cost and is a value-added service to any customer with an active CSP Agreement with Managed Solution. Managed Solution provides administrative support and escalations on behalf of the Customer to Microsoft support representatives via Managed Solution&lsquo;s Gold Partner Status. To contact Microsoft Support, please email o365@managedsolution.com. If Customer contacts Microsoft directly for support, Microsoft may redirect Customer to Managed Solution.</p>
<h5>Managed Solution Support</h5>
<p>This Agreement does not include any technical support or project services. Managed Solution Support is strictly limited to the administration of licenses and creating Microsoft support tickets on behalf of a client&lsquo;s company. Any requests outside of such administration will be considered out of scope for this Agreement. Managed Solution may provide a quote for technical support or project services which will require approval from Customer prior to providing such services.</p>
<h5>Change Requests</h5>
<p>Customer may add additional licenses beyond the baseline quantities indicated above at any time by request to Managed Solution. <strong>Customer may NOT reduce license quantities until the end of each subscription term</strong>. See Subscription Levels below for additional information.</p>
<p>To make a Change Request, please email o365@managedsolution.com. Customer is responsible for any prorated amounts that may arise. Customer may cancel a Change Request within 24 hours from the initial request.</p>
<h5>Cancellation Terms</h5>
<p>Customer may cancel each <u>individual</u> subscription only within the first 24 hours of initial Agreement placed with Managed Solution. Cancellation notice must be sent to o365@managedsolution.com. Should Customer cancel after 24 hours of initial order, Customer agrees to pay a lump sum termination fee equal to the monthly fee of each subscription multiplied by the number of months remaining for the term.</p>
<h5>Subscription Levels</h5>
<p><u>Monthly Subscription Licenses (P1M)</u></p>
<ul>
	<li>License term is monthly in one-month increments.</li>
	<li>Customer may increase quantity at any time with a pro-rated charge.</li>
	<li>Add-on licenses have their own term and may not align with the baseline subscription.</li>
	<li>Customer may <strong><u>only</u></strong> decrease quantity at the end of each monthly term.</li>
	<li><strong>Licenses will auto-renew in 1-month increments</strong> unless Customer makes a Change Request to Managed Solution at least 72 hours prior to the end of a monthly term by email to o365@managedsolution.com</li>
	<li>Licenses are <em>invoiced monthly.</em></li>
	<li>Some licenses and add-on licenses are not available as a monthly subscription.</li></ul>
<p><u>Annual Subscription Licenses (P1YM)</u></p>
<ul>
	<li>License term is annual in 12-month increments.</li>
	<li>Customer may increase quantity at any time with a pro-rated charge.</li>
	<li>Add-on licenses have their own term and may not align with the baseline subscription.</li>
	<li>Customer may <strong><u>only</u></strong> decrease quantity at the end of each annual term.</li>
	<li><strong>Licenses will auto-renew in 12-month increments</strong> unless Customer makes a Change Request to Managed Solution at least 30 days prior to the end of an annual term by email to o365@managedsolution.com.</li>
	<li>Licenses are <em>invoiced monthly, but commitment is for a <u>minimum of 12 months.</u></em></li></ul>
<p><u>Annual Subscription Licenses (P1YA)</u></p>
<ul>
	<li>License term is annual in 12-month increments.</li>
	<li>Customer may increase quantity at any time with a pro-rated charge.</li>
	<li>Add-on licenses have their own term and may not align with the baseline subscription.</li>
	<li>Customer may <strong><u>only</u></strong> decrease quantity at the end of each annual term.</li>
	<li><strong>Licenses will auto-renew in 12-month increments</strong> unless Customer makes a Change Request to Managed Solution at least 30 days prior to the end of an annual term by email to o365@managedsolution.com</li>
	<li>Licenses are <em>invoiced <strong>annually.</strong></em></li></ul>
<h5>Payment Terms</h5>
<p>Customer shall pay invoices with in 30 days of invoice date. Late payments are subject to a 2% fee and may cause disruption or delay in service. Payments are accepted by check or ACH/EFT. Should customer not pay invoice within 30 days, Managed Solution reserves the right to suspend services, but fees will continue to accrue.</p>
<h5>Notices</h5>
<p>Any notices under this Agreement for Managed Solution shall be directed to:</p>
<p>Managed Solution<br />
	ATTN: Cloud Licensing Team<br />
	1775 Hancock St, Suite 110<br />&#9;San Diego, CA 92110</p>
<p>contracts@managedsolution.com</p>
        </Typography>
      </Grid>
    </Grid>
  );
};

export default TermsConditionsPage;
