import React, { useMemo, useState } from 'react';
import "./Catalog.scss";
import SearchBar from "../../submodule/components/SearchBar/SearchBar";
import { useOfferGetApi } from "../../api/CatalogApi";
import Loader from "../../components/Loader";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import AccordionSummary from "@mui/material/AccordionSummary";
import TablePagination from '@mui/material/TablePagination'
import Accordion from "@mui/material/Accordion";
import Grid from "@mui/material/Grid";
import AccordionDetails from "@mui/material/AccordionDetails";
import { Box, Button } from "@mui/material";
import { CatalogItem, CatalogStyledBox } from "./CatalogPageStyles";
import BuySubscriptionModal from "./BuySubscriptionModal/BuySubscriptionModal";
import { debounceDelay, getPriceInFormat, getPurchaseOptionsAsObject } from "../../utils";
import { useOrderPostApi } from "../../api/OrderApi";
import AlertMessage from "../../submodule/components/AlertMessage/AlertMessage";

const ProductTitleComponent = ({ product }: { product: any }) => {
  return (
    <Typography className="accordian-item-heading">
      {product.title}
    </Typography>
  )
}

const ProductDescriptionComponent = ({ product }: { product: any }) => {
  return (
    <Grid className="item-inner-col" item xs={6} md={8}>
      <CatalogItem>
        <Typography className="accordian-inner-para">{product.description}</Typography>
      </CatalogItem>
    </Grid>
  )
}

const getPrice = (product: any, priceKey: string) => {
  const billingOptionsAsObj = getPurchaseOptionsAsObject(product.purchase_options);

  if (billingOptionsAsObj.P1M?.monthly) return `${getPriceInFormat(billingOptionsAsObj.P1M.monthly[priceKey])}/month`;
  if (billingOptionsAsObj.P1Y?.monthly) return `${getPriceInFormat(billingOptionsAsObj.P1Y.monthly[priceKey])}/year`;
  if (billingOptionsAsObj.P1Y?.annual) return `${getPriceInFormat(billingOptionsAsObj.P1Y.annual[priceKey])}/year`;
  return "N/A"
}

const ProductRetailPriceComponent = ({ product }: { product: any }) => {
  return (
    <Grid className="item-inner-col" item xs={12} md={8}>
      <CatalogItem>
        <CatalogStyledBox className="item-mspr light">
          <Typography className="item-label">MSRP:</Typography>
          <Typography className="item-label">${getPrice(product, "retail_price")}</Typography>
        </CatalogStyledBox>
      </CatalogItem>
    </Grid>
  )
}

const ProductClientDiscountComponent = ({ product }: { product: any }) => {
  return (
    <Grid className="item-inner-col" item xs={12} md={8}>
      <CatalogItem>
        <CatalogStyledBox className="item-mspr gray">
          <Typography className="item-label-bold">Client Discount:</Typography>
          <Typography className="item-label-bold">${getPrice(product, "sale_price")}</Typography>
        </CatalogStyledBox>
      </CatalogItem>
    </Grid>
  )
}

const BuySubscriptionButtonComponent = ({ onBuySubscriptionClick }: { onBuySubscriptionClick: any }) => {
  return (
    <Grid className="item-inner-col" item xs={12} md={4}>
      <CatalogItem sx={{ textAlign: `center` }}>
        <div>
          <Button
            size="small"
            style={{
              paddingLeft: `30px`,
              paddingRight: `30px`,
              height: `50px`,
              borderRadius: `50px`,
              textTransform: `inherit`,
              font: `normal normal 500 18px/80px Raleway`,
            }}
            variant="contained"
            onClick={onBuySubscriptionClick}
            className="buy-sub"
          >
            Buy Subscriptions
          </Button>
          <Typography className="accordian-last-para">
            annual commitment required
          </Typography>
        </div>
      </CatalogItem>
    </Grid>
  )
}

const CatalogPage = () => {
  const [searchText, setSearchText] = useState("");
  const [page,setPage] = useState(0);
  const [rowsPerPage,setRowsPerPage] = useState(25);
  const [selectedProduct, setProductSelected] = useState(null);
  const [messageAlert, setMessageAlert] = useState<{ type: string, message: string } | null>(null);
  const { data = [], isLoading } = useOfferGetApi();
  const saveOrder = useOrderPostApi();
  const products = useMemo(() => {
    if (searchText?.length) {
      return data.filter((dataItem: any) => dataItem.title.toLowerCase().indexOf(searchText.toLowerCase().trim()) !== -1);
    }
    return data;
  }, [searchText, data]);

  const onSearch = debounceDelay((e: any) => {
    setSearchText(e.target.value);
  }, 300);

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number,
  ) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  if (isLoading) {
    return (
      <div className="loader_wraper">
        <Loader />
      </div>
    );
  } else if (!products.length) {
    return (
      <div>
        <SearchBar onChange={onSearch} />
        <div className="catalog-panel">
          <div className="panel-light">
            <Typography sx={{ textAlign: 'center' }} variant="h5">No data found</Typography>
          </div>
        </div>
      </div>
    )
  }

  const onBuySubscriptionClick = (product: any) => {
    setProductSelected(product);
  }

  const onCloseModal = () => {
    setProductSelected(null);
  }

  const onSaveHandler = (data: any) => {
    saveOrder.mutate(data, {
      onSuccess() {
        onCloseModal();
        setMessageAlert({ type: "success", message: "Your order has been created successfully" });
      },
      onError() {
        setMessageAlert({ type: "error", message: "Failed to create your order. Try again!" });
      }
    })
  }

  return (
    <div>
      <SearchBar onChange={onSearch} />
      <div className="catalog-panel">
        <div className="panel-light">
          {products.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((product: any, index: number) => (
            <Accordion className="accordian" key={index}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
                <ProductTitleComponent product={product} />
              </AccordionSummary>
              <AccordionDetails className="accordian-details">
                <Box sx={{ flexGrow: 1 }}>
                  <Grid className="accordian-item-inner" container spacing={2}>
                    <ProductDescriptionComponent product={product} />
                    <ProductRetailPriceComponent product={product} />
                    <ProductClientDiscountComponent product={product} />
                    <BuySubscriptionButtonComponent onBuySubscriptionClick={() => onBuySubscriptionClick(product)} />
                  </Grid>
                </Box>
              </AccordionDetails>
            </Accordion>
          ))}
        </div>
        
      </div>
      <TablePagination
          sx={{background:'#e3e3e3', borderBottomLeftRadius:'10px', borderBottomRightRadius:'10px'}}
            rowsPerPageOptions={[25, 50, 100]}
            component="div"
            count={products?.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
         />
      {selectedProduct && (<BuySubscriptionModal product={selectedProduct} onClose={onCloseModal} onSave={onSaveHandler} isSaving={saveOrder.isLoading} />)}
      {messageAlert && (AlertMessage(true, messageAlert.message, messageAlert.type, () => setMessageAlert(null)))}
    </div>
  );
};

export default CatalogPage;
