/** @format */

import React, { useEffect, useState, useCallback } from "react";
import "./Header.scss";
import Menu from "@mui/material/Menu";
import { Box, ListItemIcon, MenuItem, Tooltip } from "@mui/material";
import Logout from "@mui/icons-material/Logout";
import Button from "@mui/material/Button";
import { useLocation, useNavigate } from "react-router-dom";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import MenuIcon from "@mui/icons-material/Menu";
import Table from "@mui/material/Table";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import PersonIcon from "@mui/icons-material/Person";
import axios from "axios";
import { getAuthorizationHeader } from "../../../../constants";

type PropType = {
  app: string;
};
const Header = ({ app }: PropType) => {
  const navigate = useNavigate();

  const logout = () => {
    axios
      .get(`${process.env.REACT_APP_API_BASE}/sso/v1/signout/${app}`, {
        headers: getAuthorizationHeader(),
      })
      .then(function (response) {
        sessionStorage.removeItem("display_name");
        sessionStorage.removeItem("email");
        sessionStorage.removeItem("expires_on");
        sessionStorage.removeItem("session_guid");
        navigate("./signin?e=signout");
      })
      .catch(function (error) {
        navigate("./signin?e=signout");
      });
  };
  const token = localStorage.getItem("sessionKey");

  const [userProfile, setUserProfile] = useState({ name: "", email: "" });

  const profile = useCallback(() => {
    axios
      .get(`${process.env.REACT_APP_API_BASE}/sso/v1/profile/${app}`, {
        headers: getAuthorizationHeader(),
      })
      .then(function (response) {
        if (response) {
          if (app.toLowerCase() !== "portal" &&
            response?.data?.customer_guid?.toLowerCase() !==
              process.env.REACT_APP_AUTHORIZATION_ID?.toLowerCase() &&
            response?.data?.customer_guid?.toLowerCase() !==
              process.env.REACT_APP_AUTHORIZATION_SECONDARY_ID?.toLowerCase()
          ) {
            navigate("./signin?e=Unauthorized+Account");
            sessionStorage.clear();
          }
          setUserProfile({
            name: response?.data?.display_name,
            email: response?.data?.email,
          });
          sessionStorage.setItem("display_name", response.data.display_name);
          sessionStorage.setItem("email", response.data.email);
        }
      })
      .catch(function (error) {
        if (error.response.status === 401) {
          navigate("./signin?e=timeout");
          sessionStorage.clear();
        }
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);

  useEffect(() => {
    profile();
  }, [profile]);
  React.useEffect(() => {
    if (sessionStorage.getItem("session_guid") === null) {
      navigate("./signin?e=timeout");
    }
  }, [navigate]);

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const location = useLocation();

  let pathname = location.pathname;
  let pathNameSplit = pathname.split("/");

  let titleHeading =
    pathNameSplit?.length === 3
      ? `${pathNameSplit[pathNameSplit.length - 1]}`
      : `${pathNameSplit[pathNameSplit.length - 3]} Detail`;

  function handleShowSideBar() {
    let element = document.querySelectorAll(
      ".MuiDrawer-root .MuiPaper-root"
    )[0] as HTMLInputElement | null;
    if (element != null) {
      element.style.left = "0";
    }
  }

  return (
    <header>
      <Box
        className="dashboard-header"
        sx={{
          display: `flex`,
          justifyContent: "space-between",
          margin: `0px`,
        }}
      >
        <Table>
          <TableBody>
            <TableRow className="row-heading-text-close-button">
              <TableCell
                className="responsive-menu-hamburger"
                sx={{ textDecoration: "none" }}
              >
                <MenuIcon
                  fontSize="large"
                  className="sidebar-close-icon"
                  onClick={handleShowSideBar}
                />
              </TableCell>
              <TableCell align="left">
                <h1
                  style={{ fontFamily: "Raleway", textTransform: "capitalize" }}
                >
                  {titleHeading}
                </h1>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>

        <Tooltip className="head-desk" title="Account settings">
          <Button
            className="profile-button"
            onClick={handleClick}
            size="small"
            aria-expanded={open ? "true" : undefined}
            aria-haspopup="true"
            aria-controls={open ? "account-menu" : undefined}
            variant="contained"
            endIcon={<KeyboardArrowDownIcon />}
          >
            {userProfile?.name}
            <br />
            {userProfile?.email}
          </Button>
        </Tooltip>
        <Tooltip className="head-mob" title="Account settings">
          <Button
            className="profile-button-mob"
            onClick={handleClick}
            size="small"
            aria-expanded={open ? "true" : undefined}
            aria-haspopup="true"
            aria-controls={open ? "account-menu" : undefined}
            variant="contained"
          >
            <PersonIcon />
          </Button>
        </Tooltip>
      </Box>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 1.5,
            "& .MuiAvatar-root": {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            "&:before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: "background.paper",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        <MenuItem onClick={logout}>
          <ListItemIcon>
            <Logout fontSize="small" />
          </ListItemIcon>
          Logout
        </MenuItem>
      </Menu>
    </header>
  );
};

export default Header;
