/** @format */
import axios from "axios";
import { useQuery,useMutation, useQueryClient } from 'react-query';
import { getAuthorizationHeader } from "../constants";


export function useOrderGetApi() {
  return useQuery('orders', () => axios.get(`${process.env.REACT_APP_CLIENT_API_BASE}/orders`, { headers: getAuthorizationHeader() })
    .then((response) => {
     return response.data
  }),
{
    retry:2
  }
  );
}


export function useOrderPostApi() {
  const queryClient = useQueryClient()
  const mutation = useMutation((params: { product_id: string, sku_id: string, quantity: number, term_duration: string, billing_plan: string }) => {
    return axios.post(`${process.env.REACT_APP_CLIENT_API_BASE}/order`, [{
      product_id: params.product_id,
      sku_id: params.sku_id,
      quantity: String(params.quantity),
      term_duration: params.term_duration,
      billing_cycle: params.billing_plan,

    }], { headers: getAuthorizationHeader() });
  },
  {
    onSuccess:() => {
  queryClient.invalidateQueries('orders')
    }
  }
  );

  return mutation;
}
