export function stableSort<T>(
  array: readonly T[],
  comparator: (a: T, b: T) => number
) {
  const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

export function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  if (typeof a[orderBy] === 'string' && typeof b[orderBy] === 'string') {
    // hack to convince typescript the operation is safe since we already know the type is a string
    const lowerA = (a[orderBy] as unknown as string).toLowerCase();
    const lowerB = (b[orderBy] as unknown as string).toLowerCase();

    if (lowerB < lowerA) {
      return -1;
    }
    if (lowerB > lowerA) {
      return 1;
    }
  } else {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
  }
  return 0;
}

export type Order = "asc" | "desc";

export function getComparator<T, Key extends keyof T>(
  order: Order,
  orderBy: Key
): (
  a: T,
  b: T
) => number {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

