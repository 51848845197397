/** @format */

import React, { useState, useEffect, useMemo } from "react";
import Box from "@mui/material/Box";
import { useLocation, useNavigate } from "react-router-dom";
import DownloadButton from "../../submodule/components/DownloadButton/DownloadButton";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Tooltip from '@mui/material/Tooltip';
import SearchBar from "../../submodule/components/SearchBar/SearchBar";
import {
  stableSort,
  getComparator,
  Order,
} from "../../submodule/components/Tables/Table";
import { TableStyled } from "../../submodule/components/Tables/TableStyles";
import EnhancedTableHead from "../../submodule/components/Tables/TableHead";
import "./Subscription.scss";
import { useSubscriptionGetApi } from "../../api/SubscriptionApi";
import { convertGMTtoDate } from "../../utils";
import Loader from "../../components/Loader";
import { TablePagination } from "@mui/material";

interface Data {
  id: any;
  offer_name: any;
  quantity: any;
  commitment_end_date: string;
  term_duration: any;
  will_auto_renew: any;
  status: any;
  is_nce: any;
  // action: string;
}


interface HeadCell {
  disablePadding: boolean;
  id: keyof Data;
  label: string;
  numeric: boolean;
}

const headCells: readonly HeadCell[] = [
  {
    id: "offer_name",
    numeric: false,
    disablePadding: true,
    label: "Subscription",
  },
  {
    id: "quantity",
    numeric: false,
    disablePadding: true,
    label: "Quantity",
  },
  {
    id: "commitment_end_date",
    numeric: false,
    disablePadding: true,
    label: "Renews On",
  },
  {
    id: "term_duration",
    numeric: false,
    disablePadding: true,
    label: "Terms",
  },
  {
    id: "will_auto_renew",
    numeric: false,
    disablePadding: true,
    label: "Auto Renewel",
  },
  {
    id: "status",
    numeric: false,
    disablePadding: true,
    label: "Status",
  },
];

const SubscriptionPage = () => {
  const { data, isFetching: subscribeFetching } = useSubscriptionGetApi();
  const originalRows: Data[] = useMemo(() => {
    return data
  }, [data])

  useEffect(() => {
    if (data) {
      setRows(originalRows)
    }
  }, [data, originalRows])

  const [rows, setRows] = useState<Data[] | []>(originalRows || []);

  const [searched, setSearched] = useState<string>("");
  const [order, setOrder] = React.useState<Order>("desc");
  const [orderBy, setOrderBy] = React.useState<keyof Data>("offer_name");
    const [page,setPage] = useState(0);
  const [rowsPerPage,setRowsPerPage] = useState(15);
  const location = useLocation();
  const navigate = useNavigate();

    const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number,
  ) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };


  const requestSearch = (searchedVal: string) => {
    const filteredRows = originalRows.filter((row: any) => {
      return row.offer_name.toLowerCase().includes(searchedVal.toLowerCase().trim());
    });
    setRows(filteredRows);
  };
  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: keyof Data
  ) => {
    const isAsc = orderBy === property && order === "desc";

    setOrder(isAsc ? "asc" : "desc");
    setOrderBy(property);
  };

  const onChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setSearched(event.target.value as string);
    requestSearch(event.target.value as string);
  };

  const columns = [
    "ID",
    "Offer ID",
    "Offer Name",
    "Offer Description",
    "Quantity",
    "Creation Date",
    "Effective Start Date",
    "Commitment End Date",
    "Cancellation Date",
    "Billing Cycle",
    "Billing Type",
    "Terms Duration",
    "Auto Renewal",
    "Is Trial",
    "Is NCE",
    "Status",
  ];

  const clickableRow = (row: any) => {
    navigate(`detail/${row.id}`, {
      state: { ...row, ...{ activeSideBar: location.state?.activeSideBar } },
    });
  };

  return (
    <div>
      <Box sx={{ textAlign: `right`, marginBottom: `30px`, display:'flex', alignItems:'center' }}>
      <SearchBar value={searched} onChange={onChange} />
        <DownloadButton
          rows={rows}
          columns={columns}
          filename="subscription.csv"
        />
      </Box>
      <Box>
        
        {subscribeFetching ? <Box sx={{ display: 'flex', justifyContent: 'center', paddingTop: '30px' }}>
          <Loader />
        </Box> :
        <>
          <Paper sx={{ boxShadow: "none" }}>
            <TableContainer
              className="subscription-table-container"
              style={{ marginTop: "30px" }}
            >
              <TableStyled stickyHeader aria-label="sticky table">
                <EnhancedTableHead
                  order={order}
                  orderBy={orderBy}
                  onRequestSort={handleRequestSort}
                  rowCount={rows.length}
                  headCells={headCells}
                />

                <TableBody>
                  {
                    rows.length <= 0 ?
                      <TableCell colSpan={6} sx={{ textAlign: `center` }}>
                        No data found.
                      </TableCell>
                      :
                      stableSort(rows, getComparator(order, orderBy)).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map(
                        (row, index) => {
                          return (
                            <TableRow
                              hover
                              tabIndex={-1}
                              key={row.id}
                              onClick={() => clickableRow(row)}
                            >
                              <TableCell width="30%" className="table-col-2">
                                {row.is_nce && <span className="table-tag">NCE</span>}
                                <Tooltip title={row.offer_name} placement="top" arrow>
                                  <span>{row.offer_name} </span>
                                </Tooltip>
                              </TableCell>
                              <TableCell sx={{ textAlign: 'center' }} width='10%'>{row.quantity}</TableCell>

                              <TableCell>{convertGMTtoDate(row.commitment_end_date)}</TableCell>
                              <TableCell>{row.term_duration}</TableCell>

                              <TableCell width='10%' sx={{ textAlign: 'center' }}>
                                {row.will_auto_renew ? "Yes" : "No"}
                              </TableCell>
                              <TableCell  >
                                <span className={`ms-${row?.status === "active" ? "success" : (row?.status === "suspended" ? "danger" : "primary")
                                  }`}>
                                  {row?.status}
                                </span>
                              </TableCell>
                            </TableRow>
                          );
                        }
                      )
                  }
                </TableBody>
              </TableStyled>
            </TableContainer>
          </Paper>
          <TablePagination
          sx={{background:'#e3e3e3', borderBottomLeftRadius:'10px', borderBottomRightRadius:'10px'}}
            rowsPerPageOptions={[15, 25, 50]}
            component="div"
            count={rows?.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
         />
        </>

        }
        
      </Box>
    </div>
  );
};

export default SubscriptionPage;
