import {styled} from "@mui/system";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";

export const CatalogStyledBox = styled(Box)({
  height: `77px`,
  display: `flex`,
  justifyContent: "space-between",
  alignItems: `center`,

  padding: `0 30px`,
  borderRadius: `50px`,
});

export const CatalogItem = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  //...theme.typography.body2,
  padding: theme.spacing(1),
  boxShadow: "none",
  color: theme.palette.text.secondary,
}));
