import axios from "axios";
import { useMutation } from "react-query";

type FormValues = {
    fname: string;
    lname: string;
    email: string;
    phone: string;
    noofemployee: string;
    hearaboutus: string;
  };
  
  export function useNotifyPortalMessage() {
    return useMutation((formValues: FormValues) => {
      const requestBody = {
        source: "portal",
        page: "signup",
        data: {
          "First Name": `${formValues.fname}`,
          "Last Name": `${formValues.lname}`,
          "Email": `${formValues.email}`,
          "Phone Number": `${formValues.phone}`,
          "Number Of Employees": `${formValues.noofemployee}`,
          "Hear About Us": `${formValues.hearaboutus}`,
        },
      };
  
      return axios.post(
        `${process.env.REACT_APP_API_BASE}/notify/v1/portalmessage`,
        requestBody,
        {
          headers: {
            "Access-Control-Allow-Origin": "*",
            "Content-Type": "application/json",
          },
        }
      );
      
    },{retry:2});
  };